.movie-img img{
    border-radius: 5px;
}
.movie-single-ct .bd-hd {
    color: white;
    font-size: 26px;
    font-weight: 600;
}
.movie-single-ct .bd-hd span {
    color: #ff8d00;
    font-size: 24px;
    font-weight: 400;
}
.rate {
    font-size: 14px;
    color: #6c757d;
    font-weight: 600;
}
.cursorPointer{
    cursor: pointer;
}
.disabledbutton {
    pointer-events: none;
    }

