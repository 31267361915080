.slick-list {margin: 0 -5px;}
.slick-slide>div {padding: 0 5px;}
.slick-slider  .slick-dots { 
    text-align: right;
}
.slick-slide img {
    border-radius: 5px;
    object-fit: cover;
    /* height: 410px; */
}
.slick-slider .slick-arrow{ display:  none !important;}
.slick-dots li.slick-active button:before { 
    color: #fbfc00 !important;
}
.slick-dots li button:before{  
    font-size: 12px !important;   width: 35px; color: white !important;}
.bannerImg{ position: relative;}
.bannerImg::before{
    z-index: unset;
    background: linear-gradient(to bottom,rgba(240,47,23,0) 0,rgba(0,0,0,0.9) 100%);
    position: absolute; 
    top: 0; 
    bottom: 0;
    right: 0; 
    width: 100%;
    height: 100%;
    content: "";
}
.movie-prop {
    color: white;
    margin-left: 16px;
    font-size: 12px;
}
.bannerImg:hover::before{  
    content: normal
}
.bannerImg .ml-2{ margin-left: 10px ; }

