.main-search{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 4px solid #020d18;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px; 
}
.main-search > *{
    height: 46px;
    border: none;
    background-color: #233a50 !important; 
    font-size: 14px;
    color: #e5e5e5;
    font-weight: 300;
    text-transform: none;
}
.main-search select{
    width: 20% !important;
    border-right: 1px solid #020d18; 
    text-transform: uppercase;
    background: url(../Head/drop-icon.png) no-repeat right 20px center;
    color: #ffffff;
}
.sc-eDvSVe { 
    width: 100%;
}

.form-control:focus, select:focus  {
    color: #c3c3c3;
    background-color: #fff;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.main-search .sc-eDvSVe {
    position: inherit ; 
    flex: auto;
}
.main-search div > .wrapper { 
    border: 0px solid #dfe1e5;
    border-radius: 0;
    background-color: #233a50;
    color: #fff;
    font-size: 14px; 
    position: inherit;
}
.main-search div > input{
    color: #d1d1d1;
}
.main-search div .selected {
    background-color: #344a60;
}