img.card-img {
    height: 370px;
    object-fit: cover;
    object-position: bottom;
    border-radius: 0px;
}
.card-title {
    height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    color: #1f4f7e;
}
.ml-2{ margin-left: 10px ; }
.mr-2{ margin-right: 10px ; }
.card-sm img.card-img {
    height: 288px; 
}
.card-sm .card-title { 
    font-size: 12px; 
    height: 13px;
}
.card-sm .love-box{ display: none;}
.card .movieControls{ margin-top: -30px; transition: 0.5s ease-in-out }
.card:hover .movieControls{ margin-top: 10px;  }
.movieControls button{
    transition: 0.2s ease;
    padding: 5px 7px !important;
}
.movieControls button.multiSelect{ 
    padding: 0px !important;
}
.movieControls button.multiSelect:hover{
    background-color: #000 !important;
    padding: 0px !important;
}
.movieControls button:hover{
    background-color: #dfdfdf !important;
    padding: 5px 10px !important;
}
.basic-mv-controls button {
    transition: 0.2s ease; 
    border-radius: 3px;
    margin-bottom: 5px;
}
.basic-mv-controls button:hover{
    background-color: #9e9e9e9e;
    opacity: initial;
}
.basic-mv-controls button:hover i{
    color: #ffffff !important;
}

.long-paragraph .read_more {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -4px;
    left: 0;
    z-index: 2;
    height: 22px;
}
.long-paragraph .read_more .read_more_Click{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: 600;
    height: 22px;
    text-decoration: none;
    color: #005fab;
    cursor: pointer;
}
.long-paragraph .read_more .read_more_Click:hover{ color: #4caf50;}
.long-paragraph{ 
    max-height: 200px;
    overflow: hidden;
    margin-bottom: 30px 
}
.long-paragraph .fade-text:after {
    content: '';
    width: 100%;
    height: 22px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(to right, rgb(0 0 0 / 0%) 0%, #000 85%);
    pointer-events: none;
}


.selctedMovie.card::before {
    z-index: unset;
    background: linear-gradient(to bottom,rgb(0 0 0 / 71%) 0,rgb(0 0 0 / 64%) 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: "";
}

 
  
@media only screen and (max-width : 768px) {
    .card .movieControls{ margin-top: 10px }
    img.card-img { height: auto; object-fit: fill;}
    .card-person img.card-img {
        height: 120px;
        object-fit: cover;
        object-position: top;
    }
    .card-sm img.card-img {
        height: 160px;
    }
    .horizontal_media_list {
        min-height: 200px;}
    .slick-slide img { 
        height: 260px !important;
    }
    .mainHead{
        padding-top: 45px  !important;
        margin-top: 0px !important;
        padding-bottom: 10px !important;
    }
    .mainHead.headSearch { 
        padding-bottom: 10px !important;
        padding-top: 165px  !important;
    } 
    .movie-single-ct h1.bd-hd{ font-size: 18px; margin-top: 20px;}
    header {
        margin-top: 0px !important;
        z-index: 99 !important;
    }
    header nav.navbar{
        background-color: #233a50;
        padding: 5px 5px;
    }
    button.navbar-toggler {
        /* border: 1px solid #233a50; */
        border-radius: 0px;
    } 
    div#responsive-navbar-nav {
        margin: 20px;
        font-size: 18px;
    }
    div#responsive-navbar-nav  a.nav-link {
        padding: 12px 0px;
        border-bottom: 1px solid #182b3e;
    }
    div#responsive-navbar-nav a.nav-link:last-child  { 
        border-bottom: 0px solid #182b3e;
    }
    .main-search { 
        flex-direction: column; 
        align-items: inherit !important;
    }
    header .main-search select {
        width: 100% !important; 
        border-bottom: 3px solid #020d18; 
    }
    .basic-mv-controls, .card-sm .card-body{
        display: none !important;
    }
    .skeltonMobile .react-loading-skeleton{
        width: 100% !important;
        height: 160px !important;
    }
    .skeltonMobile .card-body{
        display: none;
    }
    .custom-table td {
        width: 100%;
        display: inline-block;
        margin-bottom: 14px 
    }
    .YoutubeModal iframe{
        height: 256px;
    }
    .offcanvas {
        width: 82% !important;
    }
    .nav-link.active {
        color: #f47b0a !important;
    }
}
 