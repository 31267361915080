.footer {
    background-color: #050514;
    padding: 50px 0;
    color: #fff; 
}

h2.ft-widget-title {
    font-size: 18px; 
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 30px;
}
.ft-widget-contetn ul li {
    line-height: 32px;
}
.ft-widget-contetn ul li a {
    color: #a1a1a1;
    text-decoration: none;
}
footer {
    z-index: 3;
    position: sticky;
}
.footer-buttom {
    padding: 25px 0 20px 0;
    background-color: #02020f;
}

.ft-menu ul li {
    display: inline-block;
}
.ft-menu ul li a {
    color: #fff;
    text-transform: capitalize;
    margin-right: 14px;
}
.ft-copyright{ text-align: right; color: #4c4c4c;}
.ft-newsletter {
    color: #7c7c7c;
}
.stickyBTM a{
    padding: 12px 10px;
    font-size: 12px;
}
.stickyBTM i{ 
    font-size: 18px;
    color: #919191;
}
.stickyBTM .badge {
    background-color: #f39100 !important;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    padding: 0;
    padding-top: 4px;
}

