@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap');


html {
  scroll-behavior: smooth;
}
body {
    font-family: 'Montserrat', sans-serif;
    background-color:#f8f9fc;
    scrollbar-width: thin;
  }
body::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}
body::-webkit-scrollbar-track {
    background: transparent;
}
body::-webkit-scrollbar-thumb {
    background-color: #9f9f9f;
    border-radius: 20px;
}
header{ margin-top: 12px; z-index: 1;}
.mainHead{ 
    background-image: url(../../../public/assets/images/slider-bg.jpg);
    padding-bottom: 55px;
    padding-top: 150px;
}
.navbar a{ color: #f7f7f7;}
.navbar a.active{ color: #f47b0a !important;}
.mainHead h3{     font-size: 36px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 30px;
text-align: center;}
.singleMovie {
  background-size: cover;
  min-height: 596px;
  padding-bottom: 60px;
}
.singleMovie::before {
  content:"";
  display: block;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to top, #000000, #000000f5, #000202b8, #000000bf, #000000);
  backdrop-filter: blur(5px);
}
 
.content{ position: relative;}
.font-weight{ font-weight: 100;}
#responsive-navbar-nav .dropdown-menu{
  background-color: #233a50 !important;
}

.login-modal .modal-content{
  background-color: #233a50;
  border: 0px;
  border-radius: 0px;
  color: #f7f7f7;
}

.login-modal input{
  background-color: #091b2c
}
.login-modal input:focus{
  background-color: #04101b
}
.login-modal .submitBTN {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 15px 18px 15px;
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;
  background: #1f80e0;
  border: none;
  outline: 0;
  outline-color: transparent;
  position: relative;
  outline: none !important;
  width: 100%;
  height: 49px;
  padding: 15px 18px 15px;
}
#collasible-nav-dropdown{
  text-transform: capitalize;
}

.boxes {
  --size: 32px;
  --duration: 800ms;
  height: calc(var(--size) * 2);
  width: calc(var(--size) * 3);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  margin-top: calc(var(--size) * 1.5 * -1);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}
.boxes .box {
  width: var(--size);
  height: var(--size);
  top: 0;
  left: 0;
  position: absolute;
  transform-style: preserve-3d;
}
.boxes .box:nth-child(1) {
  transform: translate(100%, 0);
  -webkit-animation: box1 var(--duration) linear infinite;
          animation: box1 var(--duration) linear infinite;
}
.boxes .box:nth-child(2) {
  transform: translate(0, 100%);
  -webkit-animation: box2 var(--duration) linear infinite;
          animation: box2 var(--duration) linear infinite;
}
.boxes .box:nth-child(3) {
  transform: translate(100%, 100%);
  -webkit-animation: box3 var(--duration) linear infinite;
          animation: box3 var(--duration) linear infinite;
}
.boxes .box:nth-child(4) {
  transform: translate(200%, 0);
  -webkit-animation: box4 var(--duration) linear infinite;
          animation: box4 var(--duration) linear infinite;
}
.boxes .box > div {
  --background: #5c8df6;
  --top: auto;
  --right: auto;
  --bottom: auto;
  --left: auto;
  --translateZ: calc(var(--size) / 2);
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--background);
  top: var(--top);
  right: var(--right);
  bottom: var(--bottom);
  left: var(--left);
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
}
.boxes .box > div:nth-child(1) {
  --top: 0;
  --left: 0;
}
.boxes .box > div:nth-child(2) {
  --background: #145af2;
  --right: 0;
  --rotateY: 90deg;
}
.boxes .box > div:nth-child(3) {
  --background: #447cf5;
  --rotateX: -90deg;
}
.boxes .box > div:nth-child(4) {
  --background: #dbe3f4;
  --top: 0;
  --left: 0;
  --translateZ: calc(var(--size) * 3 * -1);
}

@-webkit-keyframes box1 {
  0%, 50% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box1 {
  0%, 50% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(200%, 0);
  }
}
@-webkit-keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}
@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}
@-webkit-keyframes box3 {
  0%, 50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(0, 100%);
  }
}
@keyframes box3 {
  0%, 50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(0, 100%);
  }
}
@-webkit-keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }
  50% {
    transform: translate(200%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}
@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }
  50% {
    transform: translate(200%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}
html {
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

.spinner {
  min-height: 100vh; 
  color: #adafb6;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9fbff;
}
.spinner .dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
}
.spinner .dribbble img {
  display: block;
  height: 28px;
}
 
.toast-message .Toastify__toast{
  border-radius: 50px;
}
.toast-message .Toastify__close-button { 
  margin-top: 10px;
  margin-right: 5px;
}

.card-person img.card-img {
  height: 215px;
  object-fit: cover; 
  object-position: top
}
.card-person .card-title {
  height: auto;
  display: block;  
  font-size: 12px; 
  color: #101010;
  margin-bottom: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-person p {  
  line-height: 15px
}
.card-person p small{  
  font-size: 11px;  
  color: #5c5c5c !important;
}
.card-person p small.text-bg-success{   
  color: white !important;
}
.card-person .card-body {
  height: 58px;
  padding: 6px 10px;
}

.horizontal_media_list {
  min-height: 221px;
  display: flex; 
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  overflow-x: scroll;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: auto;
  padding-bottom: 10px;
}

 
.horizontal_media_list::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
.horizontal_media_list::-webkit-scrollbar-track {
  background: transparent;
}
.horizontal_media_list::-webkit-scrollbar-thumb {
  background-color: #9f9f9f;
  border-radius: 20px;
}
.font-weight-bold{ font-weight: 600;}

.offcanvas-body a.nav-link {
  padding-top: 15px;
  padding-bottom: 15px;
  color: lightgrey;
  font-size: 18px;
  border-bottom: 1px solid #363636;
}
.offcanvas-body a.nav-link:last-child { 
  border-bottom: 0px;
}
header .btn:hover{
  background-color: inherit;
}
